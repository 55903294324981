let origin = "https://future.zhuneng.cn";
//积分
const pointsUrl = `/gateway/hc-accumulated/pointuser/getPointUserDetail`;
//党务通
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;

// 查询话题详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = "/gateway/hc-neighbour/topic/addComment";
// 话题评论列表分页查询
const getTopicCommentList = "/gateway/hc-neighbour/topic/getTopicCommentList";
// 评论回复列表分页查询
const getTopicReplyList = "/gateway/hc-neighbour/topic/getTopicReplyList";

// 判断用户是否申请过 “和” 伙人
const getUserTalentStatusURL = `/gateway/hc-neighbour/talent/getUserTalentStatus`;
//增加阅读数
const getCountUrl = `/gateway/hc-neighbour/topic/addReadCount`;
//运营位列表
const getBannerListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
export {
  getBannerListUrl,
  pointsUrl,
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  getUserTalentStatusURL,
  getCountUrl,
};
